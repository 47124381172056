import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationService } from '../shared/navigation.service';

@Component({
  selector: 'side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit, AfterViewInit {

  @ViewChild('drawer')
  drawer: ElementRef;
  constructor(private renderer: Renderer2, private navSer: NavigationService) { }


  ngAfterViewInit(): void {
    this.toggle();
  }
  ngOnInit(): void {
  }

  toggle(): void {

    if (!this.navSer.isNavOpen()) {
      this.renderer.setStyle(this.drawer.nativeElement, 'display', 'block');

    }
    if (this.navSer.isNavOpen()) {
      this.renderer.setStyle(this.drawer.nativeElement, 'display', 'none');

    }
    this.navSer.toggleNavState();

  }
}
