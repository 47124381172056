import { Contact } from './../model/contact.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { DataService } from './../shared/data.service';
import { Service } from './../model/service.model';
import { Component, OnInit } from '@angular/core';
import { SERVICES } from './../model/service.data';
import { Observable, throwError } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  // services: Service[] = Object.values(SERVICES);
  services$: Observable<Service[]>;
  formg: FormGroup = this.fb.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    details: ['', Validators.required]
  });
  contactId: string;
  constructor(private fb: FormBuilder, private ds: DataService,
              private db: AngularFirestore, private route: Router) { }

  ngOnInit(): void {
    this.services$ = this.ds.loadServices();
    this.contactId = this.db.createId();
  }

  onCreateContact() {
    const val = this.formg.value;
    const contact: Partial<Contact> = {
      name: val.name,
      email: val.email,
      details: val.details
    };

    this.ds.createContact(contact, this.contactId).pipe(
      tap(contact1 => {
        console.log('Created New contact', contact1);
        this.route.navigateByUrl('/services');
      }),
      catchError(err => {
        console.log(err);
        alert('Could not create contact');
        return throwError(err);
      })
      // tslint:disable-next-line: deprecation
    ).subscribe();

  }

  // async upload(): Promise<void>{
  //   const servicesCollection = this.db.collection('services');
  //   for (const service of Object.values<Service>(SERVICES)) {
  //     const newService = this.removeId(service);
  //     await servicesCollection.add(newService);
  //     console.log(`Uploading Skill ${service.title}`);
  //   }
  // }
  // removeId(data: any): any {
  //   const newData: any = { ...data };
  //   delete newData.id;
  //   return newData;
  // }
}
