<section class="home">
  <div class="home__img--container">
      <img src="../../assets/images/Work_Collage.jpg" class="home__img" alt="">
  </div>
  <div class="home__content">
      <div class="heading-3 filler mt-hg ">
          From Problem to Solution
      </div>
      <div class="home__content__text">
          Best Solution for your services Mainly for material handling system providers, specialized in
          installation of all type of mechanical systems and commissioning
          support in any part of India with strong technical skills and manpower
      </div>
  </div>
</section>
<section class="landing ">
  <div class="landing__content">
      <div class="landing__content--left">
          <div class="landing__content--title">About us</div>
          <div class="landing__content--text">About Our Company History</div>
          <div class="landing__content--title">What we do</div>
          <div class="landing__content--text">To make our client Happier</div>
          <div class="landing__content--title">Why us</div>
          <div class="landing__content--text">We strive to be better</div>
      </div>
      <div class="landing__content--right ">
          <div class="landing__content--text">We are pleased to introduce ourselves as one of the proven,
              trusted
              organization providing various services mainly for the leading Material Handling system
              providers in India, specialized in installation of all type of mechanical systems and commissioning
              support
              in any part of India with strong technical skills and manpower...

          </div>
          <div class="landing__content--right-btn">
              <button class="btn" routerLink="about">Explore More&rarr;</button>
          </div>
          <div class="landing__content--text">We have been executing the material handling project for 25+ years,
              our expertises are in the installation and commissioning of Overhead power, Free Conveyors of
              different systems,
              Slat Conveyor, Hydraulic Scissor left, EMS as well as AEMS systems, Fabrication, installation of
              pipeline,
              Logistic, AMC for conveyor, Lifts, Manpower and so on...

           </div>
          <div class="landing__content--right-btn">
              <button class="btn " routerLink="services">Explore More&rarr;</button>
          </div>
          <div class="landing__content--text mt-md">Our business is driven by values which we live on daily basis with
              Customer satisfaction
              at its center, adding value to our customers, affordable, quality services backed by our 25+ years
              of rich experience in room_service
              offerings. We have ever growing list of satisfied and esteemed clientele speaks about it,
              looking forward to serve you...

          </div>
          <div class="landing__content--right-btn">
              <button class="btn " routerLink="offerings">Explore More&rarr;</button>
          </div>
      </div>
  </div>
</section>

<section class="landing-small">
  <div class="landing-small__content">

          <div class="landing-small__content--title">About us</div>
          <div class="landing-small__content--text">About Our Company History</div>
          <br>
          <div class="landing-small__content--text">We are pleased to introduce ourselves as one of the proven,
              trusted
              organization providing various services mainly for the leading Material Handling system
              providers in India, specialized in installation of all type of mechanical systems and commissioning
              support
              in any part of India with strong technical skills and manpower...

          </div>
          <div class="landing-small__content-btn">
              <button class="btn "routerLink="about">Explore More&rarr;</button>
          </div>
          <div class="landing-small__content--title">What we do</div>
          <div class="landing-small__content--text">To make our client Happier</div>
          <br>
          <div class="landing-small__content--text">We have been executing the material handling project for 25+ years,
              our expertises are in the installation and commissioning of Overhead power, Free Conveyors of
              different systems,
              Slat Conveyor, Hydraulic Scissor left, EMS as well as AEMS systems, Fabrication, installation of
              pipeline,
              Logistic, AMC for conveyor, Lifts, Manpower and so on...

           </div>
          <div class="landing-small__content-btn">
              <button class="btn " routerLink="services">Explore More&rarr;</button>
          </div>
          <div class="landing-small__content--title">Why us</div>
          <div class="landing-small__content--text">We strive to be better</div>
          <br>
          <div class="landing-small__content--text mt-md">Our business is driven by values which we live on daily basis with
              Customer satisfaction
              at its center, adding value to our customers, affordable, quality services backed by our 25+ years
              of rich experience in room_service
              offerings. We have ever growing list of satisfied and esteemed clientele speaks about it,
              looking forward to serve you...

          </div>
          <div class="landing-small__content-btn">
              <button class="btn " routerLink="offerings">Explore More&rarr;</button>
          </div>
  </div>

</section>
