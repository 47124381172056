<header class="header">

 <side-bar></side-bar>
  <img src="../../assets/images/logo.png" alt="" class="header__logo filler">

  <ul class="menu">
      <li class="menu__item"><a routerLink="home" class="menu__link">Home</a></li>
      <li class="menu__item"><a routerLink="about" class="menu__link">About Us</a></li>
      <li class="menu__item"><a routerLink="services" class="menu__link">Services</a></li>
      <li class="menu__item filler"><a routerLink="offerings"  class="menu__link">Why Us</a></li>
      <span class="filler"></span>
      <span class="filler"></span>
      <span class="filler"></span>
      <li class="menu__item ">
          <a href="https://www.linkedin.com/company/sai-enterprises-1503" target="_blank" rel="noopener noreferrer">
              <svg class="menu--icon">
                  <use xlink:href="../../assets/images/sprite.svg#icon-linkedin"></use>
              </svg>
          </a>
      </li>
      <li class="menu__item">
          <a href="https://twitter.com/saiente05108671" target="_blank" rel="noopener noreferrer">
              <svg class="menu--icon">
                  <use xlink:href="../../assets/images/sprite.svg#icon-twitter"></use>
              </svg>
          </a>
      </li>
      <li class="menu__item">
          <a href="https://www.facebook.com/Sai-Enterprises-101585298813697" target="_blank" rel="noopener noreferrer">
              <svg class="menu--icon">
                  <use xlink:href="../../assets/images/sprite.svg#icon-facebook"></use>
              </svg>
          </a>
      </li>

      <span class="filler"></span>
      <span class="filler"></span>

  </ul>

</header>
