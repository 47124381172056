import { Observable } from 'rxjs';
import { CLIENTS } from './../model/client.data';
import { Offering } from './../model/offering.model';
import { DataService } from './../shared/data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit } from '@angular/core';
import { OFFERINGS } from './../model/offering.data';
import { Client } from '../model/client.model';

@Component({
  selector: 'offerings',
  templateUrl: './offerings.component.html',
  styleUrls: ['./offerings.component.scss']
})
export class OfferingsComponent implements OnInit {

  client$: Observable<Client[]>;
  offering$: Observable<Offering[]>;
  constructor(private db: AngularFirestore, private ds: DataService) { }

  ngOnInit(): void {
    this.client$ = this.ds.loadClients();
    this.offering$ = this.ds.loadOfferings();
  }

  //   async upload(): Promise<void>{
  //     const clientsCollection = this.db.collection('clients');
  //     for (const service of Object.values<Offering>(CLIENTS)) {
  //       const newService = this.removeId(service);
  //       await clientsCollection.add(newService);
  //       console.log(`Uploading Clients ${service.title}`);
  //     }
  //     // const servicesCollection = this.db.collection('offerings');
  //     // for (const service of Object.values<Offering>(OFFERINGS)) {
  //     // const newService = this.removeId(service);
  //     // await servicesCollection.add(newService);
  //     // console.log(`Uploading Offering ${service.title}`);
  //   }

  // removeId(data: any): any {
  //   const newData: any = { ...data };
  //   delete newData.id;
  //   return newData;
  // }
}
