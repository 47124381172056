<div class="about__summary">
  <!-- <button class="ml-hg btn " (click)="upload()">Upload&rarr;</button> -->
  <div class="heading-2 ml-sm mt-md mr-sm">
    We provide best Solution for your services mainly for material handling system providers, specialized in
    installation of all type of mechanical systems and commissioning
    support in any part of India with strong technical skills and manpower
  </div>
  <br>
  <br>
  <div class="heading-3 mb-md ml-sm mt-md mr-sm"> Fields Of Operations</div>
  <p class="paragraph ml-sm mt-md mr-sm">
      We have been executing the material handling project for a long period, our expertises are in the
      installation & commissioning of the following major systems:-
  </p>
  <ul>
      <div *ngFor="let offering of (offering$ | async)">
      <li class="heading-4 ml-sm" >
          <span class="about__summary--icon-box mt-sm">
              <svg class="about__summary--icon">
                  <use xlink:href="../../assets/images/sprite.svg#icon-compass"></use>
              </svg>
              &nbsp;&nbsp;{{offering.title}}
          </span>

      </li>
      <br>
      </div>
  </ul>
  <br>
  <p class="paragraph ml-sm mt-md mr-sm">
      Any other services which falls in the category of technical knowledge, we will be always ready to accept
      the challenge and put our best resources to serve your organization
  </p>
  <br>
  <br>
  <div class="heading-3 mb-md ml-sm mt-md mr-sm"> Our Clients</div>
  <p class="paragraph ml-sm mt-md mr-sm">
We have rich clientele in manufacturing, product development space, Below is the list of clients for whom we have worked:-
  </p>
  <ul>

      <li class="heading-4 ml-lg" *ngFor="let client of (client$ | async)">
          <span class="about__summary--icon-box mt-sm">
              <svg class="about__summary--icon mb-sm">
                  <use xlink:href="../../assets/images/sprite.svg#icon-aircraft-landing"></use>
              </svg>
              &nbsp;&nbsp;{{client.title}}
          </span>

      </li>

  </ul>
  <br>
  <br>
</div>
