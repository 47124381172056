<se-head></se-head>
<router-outlet></router-outlet>
<footer class="footer">
  <div class="footer__content">
      <p class="paragraph">&copy;2021: Copyright <a class="www">www.saisenterprises.in</a>
          <br>All rights reserved !
      </p>
  </div>

</footer>
