import { NavigationService } from './../shared/navigation.service';
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'se-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.scss']
})
export class HeadComponent implements OnInit {

  isOpened = false;

   constructor(private renderer: Renderer2, private navSer: NavigationService) {
      // this.toggle();
   }
  ngOnInit(): void {
  }
}