<button class="nav-btn" (click)="toggle()"></button>

  <nav  #drawer class="sidebar">
    <ul class="side-nav mt-hg">
        <li class="side-nav__item side-nav__item--active">
            <a routerLink="home"  class="side-nav__link">
                <svg class="side-nav__icon">
                    <use xlink:href="../../assets/images/sprite.svg#icon-home3"></use>
                </svg>
                <span>Home</span>
            </a>
        </li>
        <li class="side-nav__item">
            <a routerLink="about" class="side-nav__link">
                <svg class="side-nav__icon">
                    <use xlink:href="../../assets/images/sprite.svg#icon-library_books"></use>
                </svg>
                <span>About Us</span>
            </a>
        </li>
        <li class="side-nav__item">
            <a routerLink="services" class="side-nav__link">
                <svg class="side-nav__icon">
                    <use xlink:href="../../assets/images/sprite.svg#icon-suitcase"></use>
                </svg>
                <span>Services</span>
            </a>
        </li>
        <li class="side-nav__item">
            <a routerLink="offerings"  class="side-nav__link">
                <svg class="side-nav__icon">
                    <use xlink:href="../../assets/images/sprite.svg#icon-new-message"></use>
                </svg>
                <span>Why Us</span>
            </a>
        </li>
        <li class="side-nav__item">
            <a href="https://www.linkedin.com/company/sai-enterprises-1503" class="side-nav__link" target="_blank" rel="noopener noreferrer" (click)="toggle()">
                <svg class="side-nav__icon">
                    <use xlink:href="../../assets/images/sprite.svg#icon-linkedin"></use>
                </svg>
                Linkedin
            </a>
        </li>
        <li class="side-nav__item">
            <a href="https://twitter.com/saiente05108671" class="side-nav__link" target="_blank" rel="noopener noreferrer" (click)="toggle()">
                <svg class="side-nav__icon">
                    <use xlink:href="../../assets/images/sprite.svg#icon-twitter"></use>
                </svg>
                Twitter
            </a>
        </li>
        <li class="side-nav__item">
            <a href="https://www.facebook.com/Sai-Enterprises-101585298813697" class="side-nav__link" target="_blank" rel="noopener noreferrer" (click)="toggle()">
                <svg class="side-nav__icon">
                    <use xlink:href="../../assets/images/sprite.svg#icon-facebook"></use>
                </svg>
                Facebook
            </a>
        </li>

    </ul>

</nav>
