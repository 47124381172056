<section class="about">
  <div class="about__content">
      <div class="about__content--img">
        <img src="../../assets/images/IMG-20210524-WA0007.jpg" alt="" width="100%" height="100%">
      </div>
      <div class="about__content--message">
          <div class="heading-3 mb-md"> Founder's Message</div>
          <p class="paragraph">
              We are pleased to introduce ourselves as one of the proved & trusted organization for providing
              various services mainly for the leading Material Handling system providers in India in order give
              good installation & commissioning support in any part of India with good technical as well as strong
              manpower & all types of tools for proper installation of all types of systems.

              <br>
              <br>
              Our organization is registered under various statutory requirements like ESIC, PF etc. & also we
              enter that all records are maintain & complied with.

              <br>
              <br>
              The undersigned is the proprietor of the company and is a qualified person with an aggregate
              experience of Twenty Two years in the field of project installation & commissioning & logistic,&
              miscellaneous activity with India’s who of the leading Material Handling & automobile industries
              system integrator, and has done many projects in most of the big organizations in all over parts of
              India.

              <br>
              <br>
              Give an opportunity to serve your esteemed organization, be assured that we shall discharge the work
              to your entire satisfaction.
              In the meantime thanking you and looking forward to hearing from you regarding business relationship
              with you.

          <div class="heading-4">
              Yours Faithfully,
              <br>
              <br>
              Mr.V.A.Bhosale
              <br>Proprietor
          </div>
      </div>
  </div>

</section>
