import { Contact } from './../model/contact.model';
import { Client } from './../model/client.model';
import { Injectable } from '@angular/core';
import 'firebase/firestore';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { concatMap, first, map } from 'rxjs/operators';
import { convertSnaps } from './db.util';
import { Service } from '../model/service.model';
import { Offering } from '../model/offering.model';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private db: AngularFirestore) {

  }

  loadClients(): Observable<Client[]> {
    return this.db.collection('clients',
      ref => ref.orderBy('seq', 'asc')).snapshotChanges().pipe(
        map(snaps => {
          return convertSnaps<Client>(snaps);
        }),
        first()
      );
  }
  loadServices(): Observable<Service[]> {
    return this.db.collection('services',
      ref => ref.orderBy('icon', 'asc')).snapshotChanges().pipe(
        map(snaps => {
          return convertSnaps<Service>(snaps);
        }),
        first()
      );
  }
  loadOfferings(): Observable<Offering[]> {
    return this.db.collection('offerings',
      ref => ref.orderBy('title', 'asc')).snapshotChanges().pipe(
        map(snaps => {
          return convertSnaps<Offering>(snaps);
        }),
        first()
      );
  }

  createContact(contact: Partial<Contact>, contactId?: string) {

      let save$: Observable<any>;

      if (contactId) {
        save$ = from(this.db.doc(`contacts/${contactId}`).set(contact));
      }
      else {
        save$ = from(this.db.collection('contacts').add(contact));
      }
      return save$
      .pipe(
        map(res => {
          return {
            id: contactId ?? res.id,
            ...contact
          };
        })
      );
  }
}
