<section class="services">
  <!-- <button class="ml-hg btn " (click)="upload()">Upload&rarr;</button> -->
  <div class="services__title mt-sm mb-sm">Our Services </div>
  <div class="services__sub-title">Best Solutions to Your Service Needs</div>
  <div class="services__cards">
    <div class="services__cards__card" *ngFor="let service of (services$ | async)">
      <div class="services__cards__card--icon-box mt-sm" [ngSwitch]="service.icon">
        <svg class="services__cards__card--icon" *ngSwitchCase="1">
          <use xlink:href="../../assets/images/sprite.svg#icon-suitcase"></use>
        </svg>
        <svg class="services__cards__card--icon" *ngSwitchCase="2">
          <use xlink:href="../../assets/images/sprite.svg#icon-tab"></use>
        </svg>
        <svg class="services__cards__card--icon" *ngSwitchCase="3">
          <use xlink:href="../../assets/images/sprite.svg#icon-cart"></use>
        </svg>
        <svg class="services__cards__card--icon" *ngSwitchCase="4">
          <use xlink:href="../../assets/images/sprite.svg#icon-device_hub"></use>
        </svg>
      </div>
      <p class="heading-4 services__cards__card--title mt-sm">{{service.title}}</p>
      <p class="services__cards__card--content mt-sm mb-sm ">
        {{service.description}}
      </p>
    </div>
  </div>

</section>

<section class="call2action ">
  <div class="call2action__content">
    <div class="call2action__content--left">
      <div class="call2action__content--title">Address</div>
      <div class="call2action__content--text">Mahavir Arcade, Off. No-109,
        ‘G.P.’ – 68 ‘G’ Block,
        <br>MIDC, Thermax Chowk, Chinchwad, PUNE- 411019
      </div>
      <div class="call2action__content--title">Phone</div>
      <div class="call2action__content--text">+91-9225633620</div>
      <div class="call2action__content--title">Email</div>
      <div class="call2action__content--text">sai2000e@yahoo.com</div>
      <div>
        <ul class="call2action__content--social mt-md">
          <li class="call2action__content--social__item ">
            <a href="https://www.linkedin.com/company/sai-enterprises-1503" target="_blank" rel="noopener noreferrer">
              <svg class="call2action__content--social--icon">
                <use xlink:href="../../assets/images/sprite.svg#icon-linkedin"></use>
              </svg>
            </a>
          </li>
          <li class="call2action__content--social__item">
            <a href="https://twitter.com/saiente05108671" target="_blank" rel="noopener noreferrer">
              <svg class="call2action__content--social--icon">
                <use xlink:href="../../assets/images/sprite.svg#icon-twitter"></use>
              </svg>
            </a>
          </li>
          <li class="call2action__content--social__item">
            <a href="https://www.facebook.com/Sai-Enterprises-101585298813697" target="_blank" rel="noopener noreferrer">
              <svg class="call2action__content--social--icon">
                <use xlink:href="../../assets/images/sprite.svg#icon-facebook"></use>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="call2action__content--right ">
      <form  class="form" [formGroup]="formg">
        <div class="call2action__content--text mt-hg">
          Feel Free to connect us, on any of the social media handles, share details &darr;
        </div>
        <div class="mt-md">
          <input type="text" class="form__input" placeholder="Full Name" id="name" formControlName="name" required>
          <label for="name" class="form__label">Full Name</label>
        </div>
        <div class="">
          <input type="email" class="form__input" placeholder="Email Address" id="email" formControlName="email" required>
          <label for="email" class="form__label">Email</label>
        </div>
        <div class="mb-sm">
          <input type="details" class="form__input" placeholder="Details" id="details" formControlName="details" required>
          <label for="details" class="form__label">Details</label>
        </div>
        <div class="">
          <div class="mt-sm btn_portfolio">
            <button class="ml-md btn " (click)="onCreateContact()">Contact Us&rarr;</button>
            <button class="ml-hg btn " ><a href="mailto:sai2000e@yahoo.com">Email Us</a>&rarr;</button>
          </div>
        </div>
      </form>
      <div class="mb-sm"></div>
    </div>
  </div>
</section>
